<template>
  <div id="signup-header">
    <p class="signup-header-title">
      最短1分！今すぐ会員登録！
    </p>
    <p class="signup-header-attention">
      <span style="color: #e22b46">※</span>は入力/選択必須項目です
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    no: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const headerTitle = {
      '1': '会員情報入力',
      '2': '入力情報確認',
    }

    const isActive = (stepNum: string) => {
      if (stepNum === props.no) {
        return true
      }
      return false
    }

    return {
      headerTitle,
      isActive,
    }
  },
})
</script>

<style scoped>
#signup-header {
  text-align: center;
}

.signup-header-title {
  font-size: 20px;
  font-weight: bold;
}

.signup-header-attention {
  font-size: 14px;
}

.step-ber {
  display: flex;
  max-width: 50rem;
  position: relative;
  margin: 20px auto;
  text-align: center;
  padding-left: 10px !important;
  justify-content: center;
}
.step-ber li {
  font-size: 12px;
  list-style: none;
  position: relative;
  width: 33.333%;
}
.step-ber li:after {
  background: #e12b46;
  content: '';
  width: calc(100% - 24px);
  height: 4px;
  position: absolute;
  left: calc(-50% + 12px);
  top: 10px;
}
.step-ber li:first-child:after {
  display: none;
}
.step-ber li span {
  background: white;
  background: white;
  color: #e12b46;
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 21px;
  font-size: 15px;
  width: 25px;
  height: 25px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #e12b46;
}

.step-ber li div {
  color: #0000008f;
  font-weight: 600;
  font-size: 13px;
}

.step-ber .active:after {
  background: #e12b46;
}
.step-ber .active span {
  background: #e12b46;
  color: white;
}

.step-ber .active div {
  color: #e12b46;
  font-weight: 600;
  font-size: 13px;
}

@media (max-width: 600px) {
  p.signup-header-title {
    margin: 0 !important;
  }

  .signup-header-attention {
    margin: 0;
  }

  .brock-title {
    padding: 10px 5px;
    margin-bottom: 20px;
  }
}
</style>
