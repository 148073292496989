<template>
  <div class="fixLoaderWrap">
    <div class="fixLoaderBox">
      <div
        uk-spinner="ratio: 4"
        class="loading"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style scoped>
.loading {
  width: 100%;
  text-align: center;
}

.fixLoaderWrap {
  position: fixed;
  top: 0px;
  left:0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: black;
  opacity: 0.7;
  color: white;
}

.fixLoaderWrap .fixLoaderBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>