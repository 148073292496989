import { reactive, ref, Ref, UnwrapRef } from 'vue'

// 会員登録フォームInterface
export interface SignupData {
  member: {
    name: string
    kana: string
    mail: string
    tel: string
    postcode: string
    gradyear: string
    college: string
    literary: string
    department: string
    sub_department: string
    club: string
    gymnasium: string
    ref_media: string
    hope_service: string
  }
}

// STEP1フォームInterface
export interface Step1Data {
  first_name: Ref
  last_name: Ref
  first_kana: Ref
  last_kana: Ref
  mail: Ref
  tel: Ref
  postcode: Ref
  gradyear: Ref
  college: Ref
  literary: Ref
  department: Ref
  sub_department: Ref
  club: Ref
  gymnasium: Ref
}

// STEP2フォーム
export interface Step2Data {
  ref_media: Ref
  hope_service: Ref
  checkbox: Ref
}

// STEP1データ初期化
export const initStep1Data = (): UnwrapRef<Step1Data> => {
  return reactive<Step1Data>({
    first_name: ref(''),
    last_name: ref(''),
    first_kana: ref(''),
    last_kana: ref(''),
    mail: ref(''),
    tel: ref(''),
    postcode: ref(''),
    gradyear: ref(''),
    college: ref(''),
    literary: ref(''),
    department: ref(''),
    sub_department: ref(''),
    club: ref(''),
    gymnasium: ref(''),
  })
}

// STEP2データ初期化
export const initStep2Data = (): UnwrapRef<Step2Data> => {
  return reactive<Step2Data>({
    ref_media: ref(''),
    hope_service: ref(''),
    checkbox: ref(false),
  })
}
