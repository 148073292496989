import { Ref } from 'vue'
import { Step2Data, initStep2Data } from '../types/signup'
import { useField } from 'vee-validate'
import * as yup from 'yup'

export const step2Form = (): Step2Data => {
  const form = initStep2Data()

  form.ref_media = useField(
    'ref_media',
    yup.string().required('選択してください'),
    { initialValue: '' }
  )
  form.hope_service = useField(
    'hope_service',
    yup.string().required('選択してください'),
    { initialValue: '' }
  )
  form.checkbox = useField('checkbox')

  return form
}

// STEP2内容チェック
export const validate = async (form: Ref<any>): Promise<boolean> => {
  let result = true
  for (const k in form) {
    const res = await form[k].validate()
    if (result) result = res.errors.length ? false : true
  }

  return result
}
