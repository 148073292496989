import { Step1Data, Step2Data, SignupData } from '../types/signup'
import axios from 'axios'
import moment from 'moment'

/**
 * 会員登録処理
 *
 * @param {Step1Data} STEP1登録データ
 * @param {Step2Data} STEP2登録データ
 */
export const request = async (
  step1: Step1Data,
  step2: Step2Data
): Promise<void> => {
  const params = createParams(step1, step2)
  return await axios.post('/members', params)
}

/**
 * 送信パラメータ作成
 *
 * @param {Step1Data} STEP1登録データ
 * @param {Step2Data} STEP2登録データ
 *
 * @return {SignupData} 会員登録データ
 */
const createParams = (step1: Step1Data, step2: Step2Data): SignupData => {
  return {
    member: {
      name: step1.first_name.value + ' ' + step1.last_name.value,
      kana: step1.first_kana.value + ' ' + step1.last_kana.value,
      mail: step1.mail.value,
      tel: step1.tel.value,
      postcode: step1.postcode.value,
      gradyear: step1.gradyear.value,
      college: step1.college.value,
      literary: step1.literary.value,
      department: step1.department.value,
      sub_department: step1.sub_department.value,
      club: step1.club.value,
      gymnasium: step1.gymnasium.value,
      ref_media: step2.ref_media.value,
      hope_service: step2.hope_service.value,
    },
  }
}

// UI表示用の卒業年度にフォーマット
// (例)202303 --> 2023年03月
export const dispGradyear = (gradyear: string) => {
  const d = new Date(gradyear)
  if (d.getTime()) {
    const formatGradyear = moment(gradyear).format('YYYY年MM月')
    return formatGradyear
  } else {
    return gradyear
  }
}
