<template>
  <div id="signup">
    <signup-header :no="String(step)" />
    <loading v-if="disableRegisterBtn" />

    <step1
      v-if="step == 1"
      :form="step1_form"
      :club="clubRef"
      :gradyear="gradyearRef"
      :literary="literaryRef"
      :gymnasium="gymnasiumRef"
      @complete="onCompleteStep1"
    />
    <comfirm
      v-if="step == 2"
      :form="step1_form"
    />
    <step2
      v-if="step == 2"
      :disabled-register-btn="disableRegisterBtn"
      :form="step2_form"
      :ref-media="refMediaRef"
      :hope-service="hopeServiceRef"
      @back="onBackFromStep2"
      @complete="onCompleteStep2"
    />
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, toRefs, PropType } from 'vue'
import uikit from 'uikit'
import { request } from '../func/signup'
import { step1Form } from '../func/step1Form'
import { step2Form } from '../func/step2Form'
import signupHeader from './SignupHeader.vue'
import step1 from './Step1Form.vue'
import step2 from './Step2Form.vue'
import comfirm from './ComfirmForm.vue'
import loading from './LoadingSpiner.vue'

type Props = {
  club: [string]
  gymnasium: [string]
  gradyear: [string]
  literary: [string]
  refMedia: [string]
  hopeService: [string]
}

export default defineComponent({
  components: {
    signupHeader,
    step1,
    step2,
    comfirm,
    loading,
  },
  props: {
    club: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    gradyear: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    gymnasium: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    literary: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    refMedia: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    hopeService: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  setup(props: Props): unknown {
    const step = ref(1)
    const step1_form = step1Form()
    const step2_form = step2Form()
    const disableRegisterBtn = ref(false)

    // STEP1登録完了イベント
    const onCompleteStep1 = (): void => {
      step.value++
      document.getElementById('signup-form').scrollIntoView()
    }

    // STEP2からの戻るボタンイベントハンドラ
    const onBackFromStep2 = (): void => {
      step.value--
      document.getElementById('signup-form').scrollIntoView()
    }

    // STEP2登録完了イベント
    const onCompleteStep2 = async (): Promise<void> => {
      try {
        disableRegisterBtn.value = true
        await request(step1_form, step2_form)
        location.href = '/members/signup/complete'
      } catch (err) {
        console.log(err)
        disableRegisterBtn.value = false
        uikit.notification({
          message: '登録処理でエラーが発生しました。',
          status: 'danger',
          pos: 'bottom-center',
        })
      }
    }

    const { club, gymnasium, gradyear, literary, refMedia, hopeService } =
      toRefs(props)
    return {
      disableRegisterBtn,
      onCompleteStep1,
      onCompleteStep2,
      onBackFromStep2,
      step,
      step1_form,
      step2_form,
      clubRef: club,
      gradyearRef: gradyear,
      literaryRef: literary,
      gymnasiumRef: gymnasium,
      refMediaRef: refMedia,
      hopeServiceRef: hopeService,
    }
  },
})
</script>

<style scoped>
#signup {
  color: black;
  width: 65vw;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 50px;
  background-color: white;
  border-radius: 10px;
}

@media (max-width: 1100px) {
  #signup {
    width: 95vw;
  }
}
</style>
