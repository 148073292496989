<template>
  <div>
    <div class="grid-comfirm-area">
      <div class="confirm-label">
        姓名
      </div>
      <span />
      <div>
        {{ form.first_name.value + ' &emsp;' + form.last_name.value }}
      </div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        セイメイ
      </div>
      <span />
      <div>
        {{ form.first_kana.value + '&emsp;' + form.last_kana.value }}
      </div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        登録アドレス
      </div>
      <span />
      <div>{{ form.mail.value }}</div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        電話番号
      </div>
      <span />
      <div>{{ form.tel.value }}</div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        郵便番号
      </div>
      <span />
      <div>{{ form.postcode.value }}</div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        卒業予定年
      </div>
      <span />
      <div>{{ dispGradyear(form.gradyear.value) }}</div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        学校名
      </div>
      <span />
      <div>{{ form.college.value }}</div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        文理系統
      </div>
      <span />
      <div>{{ form.literary.value }}</div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        学部
      </div>
      <span />
      <div>{{ form.department.value }}</div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        学科
      </div>
      <span />
      <div>{{ form.sub_department.value }}</div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        クラブ名
      </div>
      <span />
      <div>{{ form.club.value }}</div>
    </div>

    <div class="grid-comfirm-area">
      <div class="confirm-label">
        体育会所属有無
      </div>
      <span />
      <div>{{ form.gymnasium.value }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { dispGradyear } from '../func/signup'

export default {
  props: {
    form: {
      type: Object as any,
      required: true,
    },
  },

  setup() {
    return {
      dispGradyear,
    }
  },
}
</script>

<style scoped>
.grid-comfirm-area {
  display: grid;
  grid-template-columns: 5fr 1fr 5fr;
  font-size: 14px;
  word-break: break-all;
  border-bottom: dotted 1px;
  border-color: #999 !important;
  margin: 10px 30%;
}

.confirm-label {
  font-weight: 600;
  text-align: right;
}

@media (max-width: 950px) {
  .grid-comfirm-area {
    margin: 5px 5%;
  }
}
</style>
