import { Ref } from 'vue'
import { Step1Data, initStep1Data } from '../types/signup'
import { useField } from 'vee-validate'
import * as yup from 'yup'

// STEP1内容データ返却
export const step1Form = (): Step1Data => {
  const form = initStep1Data()

  form.first_name = useField(
    'first_name',
    yup.string().required('姓を入力してください'),
    { initialValue: '' }
  )
  form.last_name = useField(
    'last_name',
    yup.string().required('名を入力してください'),
    { initialValue: '' }
  )
  form.first_kana = useField(
    'first_kana',
    yup.string().required('セイを入力してください'),
    { initialValue: '' }
  )
  form.last_kana = useField(
    'last_kana',
    yup.string().required('メイを入力してください'),
    { initialValue: '' }
  )
  form.mail = useField(
    'mail',
    yup
      .string()
      .required('メールアドレスを入力してください')
      .email('メールアドレスの形式が不正です'),
    { initialValue: '' }
  )
  form.tel = useField(
    'tel',
    yup
      .string()
      .required('電話番号を入力してください')
      .matches(/^0\d{10}$/, 'ハイフンなし11桁で入力してください'),
    { initialValue: '' }
  )
  form.postcode = useField(
    'postcode',
    yup
      .string()
      .required('郵便番号を入力してください')
      .matches(/^[0-9]{7}$/, 'ハイフンなし7桁で入力してください'),
    { initialValue: '' }
  )
  form.gradyear = useField(
    'gradyear',
    yup.string().required('卒業年度を選択してください'),
    { initialValue: '' }
  )
  form.college = useField(
    'college',
    yup.string().required('学校名を入力してください'),
    { initialValue: '' }
  )
  form.literary = useField(
    'literary',
    yup.string().required('文理系統を選択してください'),
    { initialValue: '' }
  )
  form.department = useField(
    'department',
    yup.string().required('学部を入力してください'),
    { initialValue: '' }
  )
  form.sub_department = useField(
    'sub_department',
    yup.string().required('学科を入力してください'),
    { initialValue: '' }
  )
  form.club = useField(
    'club',
    yup.string().required('クラブ名を選択してください'),
    { initialValue: '' }
  )
  form.gymnasium = useField(
    'gymnasium',
    yup.string().required('体育会所属有無の選択をしてください'),
    {
      initialValue: '',
    }
  )

  return form
}

// STEP1内容チェック
export const validate = async (form: Ref<any>): Promise<boolean> => {
  let result = true
  for (const k in form) {
    const res = await form[k].validate()
    if (result) result = res.errors.length ? false : true
  }

  return result
}
