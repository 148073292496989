<template>
  <div
    class="uk-alert-danger"
    uk-alert
  >
    <p>{{ message }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

type Props = {
  message: string
}

export default defineComponent({
  props: {
    message: {
      type: String,
      required: true,
    },
  },

  setup(props: Props) {
    return {
      props,
    }
  },
})
</script>

<style scoped>
.uk-alert-danger {
  padding: 10px !important;
  margin: 3px 0 !important;
  font-size: 85% !important;
}
</style>
